import { h, resolveComponent } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout";
import LayoutCard from "@/layouts/LayoutCard";
import NotFound from "@/views/pages/Page404";

const routes = [
  //404
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },

  {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    redirect: "/dashboard",
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("accessToken");
      console.log("Token yang disimpan: ", token); //
      if (!token) {
        next("/pages/login");
      } else {
        next();
      }
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },

      //kurikulum
      {
        path: "/kurikulum",
        name: "Kurikulum",
        component: LayoutCard,
        redirect: "/kurikulum/beranda",
        children: [
          {
            path: "/kurikulum/beranda",
            name: "Beranda Kurikulum Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum",
            name: "Kelola Kurikulum",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum/kelolakurikulum/lihatkurikulum",
            children: [
              {
                path: "/kurikulum/kelolakurikulum/lihatkurikulum",
                name: "Lihat Kurikulum",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
              },
              {
                path: "/kurikulum/kelolakurikulum/tambahmatkul",
                name: "Tambah Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
              },
              {
                path: "/kurikulum/kelolakurikulum/peminatanprodi",
                name: "Kelola Peminatan Prodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/KelolaPeminatanProdi.vue"),
              },
              {
                path: "/kurikulum/kelolakurikulum/impormatkul",
                name: "Impor Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
              },
              {
                path: "/kurikulum/kelolakurikulum/editmatakuliah/:code",
                name: "Detail Edit Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
              },
            ],
          },
          {
            path: "/kurikulum/persetujuankurikulum",
            name: "Persetujuan Kurikulum",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
            children: [
              {
                path: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
                name: "Riwayat Kurikulum",
                component: () =>
                  import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
              },
              {
                path: "/kurikulum/persetujuankurikulum/detail/:id",
                name: "Detail Riwayat Kurikulum",
                component: () =>
                  import(
                    "@/views/kurikulum_kaprodi/DetailRiwayatKurikulum.vue"
                  ),
              },
              {
                path: "/kurikulum/persetujuankurikulum/detailmatkul/:id",
                name: "Detail Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/DetailMatkul.vue"),
              },
              {
                path: "/kurikulum/persetujuankurikulum/aturapproval",
                name: "Atur Approval",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturApproval.vue"),
              },
              {
                path: "/kurikulum/logkurikulum/:id",
                name: "Log Kurikulum",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LogKurikulum.vue"),
              },
              {
                path: "/kurikulum/persetujuankurikulum/pilihtipe/:id",
                name: "Pilih Tipe",
                component: () =>
                  import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
              },
              {
                path: "/kurikulum/persetujuankurikulum/editmatkul/:id",
                name: "Edit Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
              },
            ],
          },
          {
            path: "/kurikulum/silabus",
            name: "Silabus Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum/silabus/aturrps",
            children: [
              {
                path: "/kurikulum/silabus/aturrps",
                name: "Atur RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturRPS.vue"),
              },
              {
                path: "/kurikulum/silabus/aturrps/aturgbpp/:id",
                name: "Atur GBPP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturGBPP.vue"),
              },
              {
                path: "/kurikulum/silabus/aturrps/atursap/:id",
                name: "Atur SAP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturSAP.vue"),
              },
              {
                path: "/kurikulum/silabus/lihatrps",
                name: "Lihat RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatRPS.vue"),
              },
              {
                path: "/kurikulum/silabus/lihatrps/lihatgbpp/:id",
                name: "Lihat GBPP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatGBPP.vue"),
              },
              {
                path: "/kurikulum/silabus/lihatrps/lihatsap/:id",
                name: "Lihat SAP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatSAP.vue"),
              },
              {
                path: "/kurikulum/silabus/statusinputrps",
                name: "Status Input RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/StatusInputRPS.vue"),
              },
              {
                path: "/kurikulum/silabus/ubahrps",
                name: "Ubah RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/UbahRPS.vue"),
              },
            ],
          },
          {
            path: "/kurikulum/laporanmatkul",
            name: "Laporan Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
          },
          {
            path: "/kurikulum/laporanmatkul/:id",
            name: "Laporan Matkul",
            component: () =>
              import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
          },
          {
            path: "/kurikulum/usermanual",
            name: "User Manual",
            component: () =>
              import("@/views/kurikulum_kaprodi/ManualKaprodi.vue"),
          },
        ],
      },

      //akun
      {
        path: "/akun",
        name: "Akun",
        component: LayoutCard,
        redirect: "/akun/beranda",
        children: [
          {
            path: "/akun/beranda",
            name: "Beranda Akun",
            component: () => import("@/views/akun/Beranda.vue"),
          },
          {
            path: "/akun/profilku",
            name: "Profilku",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/akun/profilku/editprofil",
            children: [
              {
                path: "/akun/profilku/editprofil",
                name: "Edit Profil",
                component: () => import("@/views/akun/EditProfil.vue"),
              },
              {
                path: "/akun/profilku/ubahpassword",
                name: "Ubah Password",
                component: () => import("@/views/akun/UbahPassword.vue"),
              },
            ],
          },
          {
            path: "/akun/unduhberkas",
            name: "Unduh Berkas",
            component: () => import("@/views/akun/UnduhBerkas.vue"),
          },
          {
            path: "/akun/riwayatlogin",
            name: "Riwayat Login",
            component: () => import("@/views/akun/RiwayatLogin.vue"),
          },
          {
            path: "/akun/pengawasujian",
            name: "Pengawas Ujian",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/akun/pengawasujian/pendaftaranpengawas",
            children: [
              {
                path: "/akun/pengawasujian/pendaftaranpengawas",
                name: "Pendaftaran Pengawas Ujian",
                component: () => import("@/views/akun/PendaftaranPengawas.vue"),
              },
            ],
          },
        ],
      },

      //pmb camaba
      {
        path: "/pmb-camaba",
        name: "PMB CAMABA",
        component: LayoutCard,
        redirect: "/pmb-camaba/beranda",
        children: [
          {
            path: "/pmb-camaba/uploadpernyataan",
            name: "Upload Pernyataan Keuangan",
            component: () => import("@/views/Camaba/Beranda.vue"),
          },
          {
            path: "/pmb-camaba/beranda",
            name: "Beranda",
            component: () => import("@/views/Camaba/AlurSistem.vue"),
          },
          {
            path: "/pmb-camaba/statuspembayaran",
            name: "Status Pembayaran",
            component: () => import("@/views/Camaba/StatusPembayaran.vue"),
          },
          {
            path: "/pmb-camaba/mengisidatapeserta",
            name: "Mengisi Data Peserta",
            component: () => import("@/views/Camaba/MengisiDataPeserta.vue"),
          },
          {
            path: "/pmb-camaba/viewdatapeserta",
            name: "View Data Peserta",
            component: () => import("@/views/Camaba/ViewDataPeserta.vue"),
          },
          {
            path: "/pmb-camaba/dokumenpeserta",
            name: "Dokumen Peserta PMB",
            component: () => import("@/views/Camaba/DokumenPesertaPMB.vue"),
          },
          {
            path: "/pmb-camaba/undurdiri",
            name: "Pengajuan Undur Diri Peserta CAMABA",
            component: () => import("@/views/Camaba/PengajuanUndurDiri.vue"),
          },
          {
            path: "/pmb-camaba/undurdiri/:id",
            name: "Undur Diri Peserta",
            component: () => import("@/views/Camaba/UndurDiri.vue"),
          },
          {
            path: "/pmb-camaba/cetakdokumen",
            name: "Cetak Dokumen",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb-camaba/cetakdokumen/beritaacara",
            children: [
              {
                path: "/pmb-camaba/cetakdokumen/beritaacara",
                name: "Berita Acara",
                component: () => import("@/views/Camaba/BeritaAcara.vue"),
              },
              {
                path: "/pmb-camaba/cetakdokumen/statusdok",
                name: "Status Dokumen",
                component: () => import("@/views/Camaba/StatusDokumen.vue"),
              },
            ],
          },
        ],
      },

      //pmb
      {
        path: "/pmb",
        name: "PMB",
        component: LayoutCard,
        redirect: "/pmb",
        children: [
          {
            path: "/pmb/beranda",
            name: "Beranda PMB",
            component: () => import("@/views/PMB/Beranda.vue"),
          },
          {
            path: "/pmb/pengunduranDiri",
            name: "Form Pengunduran Diri",
            component: () => import("@/views/PMB/pengunduranDiri.vue"),
          },
          {
            path: "/pmb/daftarPeserta",
            name: "Daftar Peserta",
            component: () => import("@/views/PMB/daftarPeserta.vue"),
          },
          {
            path: "/pmb/daftarPesertaOnSite",
            name: "Daftar Peserta Registrasi On Site",
            component: () => import("@/views/PMB/daftarPesertaOnSite.vue"),
          },
          {
            path: "/pmb/registrasi/check/:id",
            name: "Registrasi Check",
            component: () => import("@/views/pmb_adm/RegistrasiCheck.vue"),
          },

          {
            path: "/pmb/pembayaran",
            name: "Pembayaran PMB",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/pembayaran",
            children: [
              {
                path: "/pmb/pembayaran/beasiswa",
                name: "Beasiswa PMB",
                component: () => import("@/views/PMB/pembayaran/Beasiswa.vue"),
              },
              {
                path: "/pmb/pembayaran/InputPembayaran",
                name: "Input Pembayaran",
                component: () =>
                  import("@/views/PMB/pembayaran/InputPembayaran.vue"),
              },
              {
                path: "/pmb/pembayaran/KonfirmasiPembayaran",
                name: "Konfirmasi Pembayaran PMB",
                component: () =>
                  import("@/views/PMB/pembayaran/KonfirmasiPembayaran.vue"),
              },
              {
                path: "/pmb/pembayaran/pengembalian",
                name: "Pengembalian Pembayaran Keuangan",
                component: () =>
                  import("@/views/pmb_keu/pembayaran/Pengembalian.vue"),
              },
            ],
          },
          {
            path: "/pmb/Laporan",
            name: "Laporan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/laporan",
            children: [
              {
                path: "/pmb/Laporan/Agama",
                name: "Laporan Distribusi Agama",
                component: () => import("@/views/PMB/Laporan/Agama.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
                name: "Laporan Registrasi Per Tanggal",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanRegistrasiPerTanggal.vue"),
              },
              {
                path: "/pmb/Laporan/laporanPembayaran",
                name: "Laporan Pembayaran PMB",
                component: () =>
                  import("@/views/PMB/Laporan/laporanPembayaran.vue"),
              },
              {
                path: "/pmb/Laporan/laporanPembayaranCetakBA",
                name: "Laporan Pemabayaran Cetak BA",
                component: () =>
                  import("@/views/PMB/Laporan/laporanPembayaranCetakBA.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanOnsite",
                name: "Laporan Registrasi On Site per Tanggal",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanOnsite.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanKontakPeserta",
                name: "Laporan Kontak Peserta",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanKontak.vue"),
              },
              {
                path: "/pmb/Laporan/PesertaPmb",
                name: "Laporan Peserta PMB",
                component: () => import("@/views/PMB/Laporan/PesertaPmb.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanDistribusiJK",
                name: "Laporan Distribusi Jenis Kelamin",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanDistribusiJK.vue"),
              },
              {
                path: "/pmb/Laporan/DokumenRegonsite",
                name: "Laporan Dokumen Regonsite",
                component: () =>
                  import("@/views/PMB/Laporan/DokumenRegonsite.vue"),
              },
            ],
          },

          {
            path: "/pmb/pengaturan",
            name: "Pengaturan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/pengaturan",
            children: [
              {
                path: "/pmb/pengaturan/ListDokumen",
                name: "List Dokumen Peserta PMB",
                component: () =>
                  import("@/views/PMB/Pengaturan/ListDokumen.vue"),
              },
              {
                path: "/pmb/pengaturan/InputFinnet",
                name: "Input pembayaran Finnet",
                component: () =>
                  import("@/views/PMB/Pengaturan/InputFinnet.vue"),
              },
              {
                path: "/pmb/pengaturan/Manual",
                name: "Download Manual Pengguna",
                component: () => import("@/views/PMB/Bantuan/Manual.vue"),
              },
              {
                path: "/pmb/pengaturan/generetekelas",
                name: "Generete Kelas",
                component: () =>
                  import("@/views/PMB/Pengaturan/GenereteKelas.vue"),
              },
              {
                path: "/pmb/pengaturan/jadwalseleksireg",
                name: "Jadwal Seleksi Registrasi",
                component: () =>
                  import("@/views/PMB/Pengaturan/JadwalRegistrasi.vue"),
              },
            ],
          },
          {
            path: "/pmb/bantuan",
            name: "Bantuan PMB",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/bantuan",
            children: [
              {
                path: "/pmb/bantuan/Manual",
                name: "Download Manual Pengguna",
                component: () => import("@/views/PMB/Bantuan/Manual.vue"),
              },
            ],
          },
        ],
      },

      //registrasi
      {
        path: "/registrasi",
        name: "Registrasi",
        component: LayoutCard,
        redirect: "/registrasi/beranda",
        children: [
          {
            path: "/registrasi/beranda",
            name: "Beranda Registrasi",
            component: () => import("@/views/registrasi/Beranda.vue"),
          },
          {
            path: "/registrasi/reg",
            name: "Regist",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/reg/tagihanregis",
            children: [
              {
                path: "/registrasi/reg/tagihanregis",
                name: "Tagihan Registrasi",
                component: () =>
                  import("@/views/registrasi/TagihanRegistrasi.vue"),
              },
              {
                path: "/registrasi/reg/detailpembayaran/:id",
                name: "Detail Pembayaran",
                component: () =>
                  import("@/views/registrasi/DetailPembayaran.vue"),
              },
              {
                path: "/registrasi/reg/registrasimatkul",
                name: "Registrasi Mata Kuliah",
                component: () =>
                  import("@/views/registrasi/RegistrasiMatkul.vue"),
              },
              {
                path: "/registrasi/reg/statusregis",
                name: "Status Registrasi",
                component: () =>
                  import("@/views/registrasi/StatusRegistrasi.vue"),
              },
              {
                path: "/registrasi/reg/arsipksm",
                name: "Arsip KSM",
                component: () => import("@/views/registrasi/ArsipKSM.vue"),
              },
              {
                path: "/registrasi/reg/historyregis",
                name: "History Proses Registrasi",
                component: () =>
                  import("@/views/registrasi/HistoryProsesRegistrasi.vue"),
              },
            ],
          },
          {
            path: "/registrasi/jadwal",
            name: "Jadwal",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/jadwal/jadwalmhs",
            children: [
              {
                path: "/registrasi/jadwal/jadwalmhs",
                name: "Jadwal Mahasiswa",
                component: () =>
                  import("@/views/registrasi/JadwalMahasiswa.vue"),
              },
              {
                path: "/registrasi/jadwal/jadwalujianmhs",
                name: "Jadwal Ujian Mahasiswa",
                component: () =>
                  import("@/views/registrasi/JadwalUjianMahasiswa.vue"),
              },
              {
                path: "/registrasi/jadwal/kalenderakademik",
                name: "Kalender Akademik",
                component: () =>
                  import("@/views/registrasi/KalenderAkademik.vue"),
              },
              {
                path: "/registrasi/jadwal/importjadwal",
                name: "Import Jadwal",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
              },
              {
                path: "/registrasi/jadwal/jadwaldosen",
                name: "Jadwal Dosen",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/JadwalDosen.vue"),
              },
            ],
          },
          {
            path: "/registrasi/bantuan",
            name: "Bantuan Registrasi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/bantuan/manualpengguna",
            children: [
              {
                path: "/registrasi/bantuan/manualpengguna",
                name: "Registrasi Manual Pengguna",
                component: () =>
                  import("@/views/registrasi/ManualPengguna.vue"),
              },
            ],
          },
          {
            path: "/registrasi/beranda",
            name: "Beranda Registrasi Kaprodi",
            component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
          },
          {
            path: "/registrasi/pilihmahasiswa",
            name: "Pilih Mahasiswa",
            component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
          },
          {
            path: "/registrasi/regis",
            name: "Registrasi Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/regis/regismatkul",
            children: [
              {
                path: "/registrasi/regis/regismatkul",
                name: "Registrasi Mata Kuliah Kaprodi",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Regis_Matkul.vue"),
              },
              {
                path: "/registrasi/regis/statusmahasiswa",
                name: "Status Registrasi Mahasiswa ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Status.vue"),
              },
              {
                path: "/registrasi/regis/arsip",
                name: "Arsip KSM ",
                component: () => import("@/views/Registrasi_Kaprodi/Arsip.vue"),
              },
              {
                path: "/registrasi/regis/history",
                name: "History Proses Registrasi ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/History.vue"),
              },
            ],
          },
          {
            path: "/registrasi/matkul",
            name: "Mata Kuliah ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/matkul/daftarmatkul",
            children: [
              {
                path: "/registrasi/matkul/daftarmatkul",
                name: "Mata Kuliah Tawar",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/tambahmatkul",
                name: "Tambah Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/TambahMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/impormatkul",
                name: "Impor Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/ImporMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/alokasimatkul",
                name: "Alokasi Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/AlokasiMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/ubahmatkul/:id",
                name: "Ubah Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/UbahMatkul.vue"),
              },
              {
                path: "/registrasi/matkul/koordinatormatkul",
                name: "Koordinator Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/KoordinatorMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/statusmatkul",
                name: "Status Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/StatusMatKul.vue"),
              },
              {
                path: "/registrasi/matkul/tamatkul",
                name: "Mendaftarkan  Mata Kuliah TA/PA",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/TaMatKul.vue"),
              },
            ],
          },
          {
            path: "/registrasi/pengaturan",
            name: "Pengaturan Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/pengaturan/aturansks",
            children: [
              {
                path: "/registrasi/pengaturan/aturansks",
                name: "Aturan Maks SKS",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/AturanMaksSKS.vue"),
              },
            ],
          },
          {
            path: "/registrasi/pengaturan",
            name: "Master",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/master/ruangan",
            children: [
              {
                path: "/registrasi/master/ruangan",
                name: "Ruangan",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Ruangan.vue"),
              },
            ],
          },
          // {
          //   path: "/jadwal-kaprodi",
          //   name: "Jadwal",

          //   redirect: "/jadwal-kaprodi/tambahjadwal",
          //   children: [
          //     {
          //       path: "/jadwal-kaprodi/tambahjadwal",
          //       name: "Tambah Jadwal",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/TambahJadwal.vue"),
          //     },
          //     {
          //       path: "/jadwal-kaprodi/lihatjadwal",
          //       name: "lihat Jadwal",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
          //     },
          //     {
          //       path: "/jadwal-kaprodi/daftarmahasiswamatkul/:id",
          //       name: "Daftar Mahasiswa Mata Kuliah",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
          //     },
          //     {
          //       path: "/jadwal-kaprodi/editjadwal/:id",
          //       name: "Edit Jadwal",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
          //     },
          //     {
          //       path: "/jadwal-kaprodi/ruangkosong",
          //       name: "Ruang Kosong",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/RuangKosong.vue"),
          //     },
          //     {
          //       path: "/jadwal-kaprodi/importjadwal",
          //       name: "Import Jadwal",
          //       component: () =>
          //         import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
          //     },
          //   ],
          // },
        ],
      },

      //presensi
      {
        path: "/presensi",
        name: "Presensi",
        component: LayoutCard,
        redirect: "/presensi/beranda",
        children: [
          {
            path: "/presensi/beranda",
            name: "Beranda Presensi",
            component: () => import("@/views/presensi/Beranda.vue"),
          },
          {
            path: "/presensi/rfidlog",
            name: "RFID Log",
            component: () => import("@/views/presensi/RFIDLog.vue"),
          },
          {
            path: "/presensi/kehadiran",
            name: "Kehadiran2",
            component: () => import("@/views/perkuliahan/Kehadiran.vue"),
          },

          {
            path: "/presensi/kehadiranmhs",
            name: "Kehadiran Mahasiswa",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi/kehadiranmhs/per-perkuliahan",
            children: [
              {
                path: "/presensi/kehadiranmhs/per-perkuliahan",
                name: "Kehadiran per Perkuliahan",
                component: () =>
                  import("@/views/Dosen/presensi/KehadiranperPerkuliahan.vue"),
              },
            ],
          },
          {
            path: "/presensi/bap",
            name: "Berita Acara Perkuliahan",
            component: () => import("@/views/Dosen/presensi/BAP.vue"),
          },
          {
            path: "/presensi/submit-bap/:id",
            name: "Submit BAP",
            component: () => import("@/views/Dosen/presensi/SubmitBAP.vue"),
          },
          {
            path: "/presensi/cetakdaftarhadir",
            name: "Cetak Daftar Hadir",
            component: () =>
              import("@/views/Dosen/presensi/CetakDaftarHadir.vue"),
          },
          {
            path: "/presensi/evaluasipembelajaran",
            name: "Evaluasi Pembelajaran",
            component: () =>
              import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
          },
          {
            path: "/presensi/honor",
            name: "Honor Kehadiran",
            component: () =>
              import("@/views/Dosen/presensi/HonorKehadiran.vue"),
          },
          {
            path: "/presensi/jadwaldosen",
            name: "Jadwal Dosen Presensi",
            component: () => import("@/views/Dosen/presensi/JadwalDosen.vue"),
          },
          {
            path: "/presensi/daftarmahasiswamk/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Dosen/presensi/DaftarMahasiswaMK.vue"),
          },
          {
            path: "/presensi/laporanperkuliahan/rekapkehadirandosen",
            name: "Rekap Kehadiran Dosen",
            component: () =>
              import("@/views/Presensi_Kaprodi/RekapKehadiranDosen.vue"),
          },
        ],
      },

      //nilai
      {
        path: "/nilai",
        name: "Nilai",
        component: LayoutCard,
        redirect: "/nilai/beranda",
        children: [
          {
            path: "/nilai/beranda",
            name: "Beranda Nilai",
            component: () => import("@/views/nilai/Beranda.vue"),
          },
          {
            path: "/nilai/khs",
            name: "KHS",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/nilai/khs/lihatekivalensi",
            children: [
              {
                path: "/nilai/khs/lihatekivalensi",
                name: "Lihat Ekivalensi",
                component: () => import("@/views/nilai/LihatEkivalensi.vue"),
              },
              {
                path: "/nilai/khs/lihatnilai",
                name: "Lihat Nilai",
                component: () => import("@/views/nilai/LihatNilai.vue"),
              },
              {
                path: "/nilai/khs/lihatnilaisementara",
                name: "Lihat Nilai Sementara",
                component: () =>
                  import("@/views/nilai/LihatNilaiSementara.vue"),
              },
            ],
          },
          {
            path: "/nilai/riwayatinput",
            name: "Riwayat Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
          },
          {
            path: "/nilai/listmatkul",
            name: "List Mata Kuliah ",
            component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
          },
          {
            path: "/nilai/inputnilai/:id",
            name: "Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
          },
        ],
      },
      {
        path: "/nilaidosen",
        name: "Nilai Dosen",
        component: LayoutCard,
        redirect: "/nilaidosen/berandanilai",
        children: [
          {
            path: "/nilaidosen/berandanilai",
            name: "Beranda_Nilai ",
            component: () => import("@/views/Nilai_Dosen/Beranda.vue"),
          },
          {
            path: "/nilaidosen/riwayatinput",
            name: "Riwayat Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
          },
          {
            path: "/nilaidosen/listmatkul",
            name: "List Mata Kuliah ",
            component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
          },
          {
            path: "/nilaidosen/inputnilai/:id",
            name: "Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
          },
        ],
      },

      //beasiswa
      {
        path: "/beasiswa",
        name: "Beasiswa",
        component: LayoutCard,
        redirect: "/beasiswa/lihatjenisbeasiswa",
        children: [
          {
            path: "/beasiswa/lihatjenisbeasiswa",
            name: "Lihat Jenis Beasiswa",
            component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa",
            name: "Beasiswa Mahasiswa",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            children: [
              {
                path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
                name: "Lihat Jenis Beasiswa Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
                name: "Edit Aturan Beasiswa",
                component: () =>
                  import("@/views/beasiswa/EditAturanBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
                name: "Tambah Beasiswa",
                component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
                name: "Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/PenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
                name: "Lihat Penerima Beasiswa Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/importpenerima",
                name: "Import Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
                name: "Approval Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
                name: "Approval Status Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
              },
            ],
          },
          {
            path: "/beasiswa/beasiswamaba",
            name: "Potongan Mahasiswa Baru ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
            children: [
              {
                path: "/beasiswa/beasiswamaba/jenispotongan",
                name: "Potongan Calon Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
                name: "Lihat Jenis Potongan Maba",
                component: () =>
                  import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
              },
              {
                path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
                name: "Lihat Penerima Potongan",
                component: () =>
                  import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
              },
            ],
          },
          {
            path: "/beasiswa/laporanbeasiswa",
            name: "Laporan Beasiswa ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
            children: [
              {
                path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
                name: "Laporan Beasiswa Per Semester",
                component: () =>
                  import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
                name: "Laporan Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
                name: "Laporan Per Prodi",
                component: () =>
                  import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanpotongan",
                name: "Laporan Potongan",
                component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
              },
            ],
          },
        ],
      },

      //adm akademik
      {
        path: "/adm-akademik",
        name: "Administrasi Akademik",
        component: LayoutCard,
        redirect: "/adm-akademik/beranda",
        children: [
          {
            path: "/adm-akademik/beranda",
            name: "Beranda Adm Akademik",
            component: () => import("@/views/adm_akademik/Beranda.vue"),
          },
          {
            path: "/adm-akademik/master",
            name: "Master",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/adm-akademik/master/kurikulum",
            children: [
              {
                path: "/adm-akademik/master/kurikulum",
                name: "Tahun Kurikulum",
                component: () => import("@/views/adm_akademik/Kurikulum.vue"),
              },
              {
                path: "/adm-akademik/master/thnajar",
                name: "Tahun Ajar",
                component: () => import("@/views/adm_akademik/TahunAjar.vue"),
              },
              {
                path: "/adm-akademik/master/fakultas",
                name: "Fakultas",
                component: () => import("@/views/adm_akademik/Fakultas.vue"),
              },
              {
                path: "/adm-akademik/master/prodi",
                name: "Program Studi",
                component: () => import("@/views/adm_akademik/Prodi.vue"),
              },
              {
                path: "/adm-akademik/master/ruangan",
                name: "Ruangan",
                component: () => import("@/views/adm_akademik/Ruangan.vue"),
              },
              {
                path: "/adm-akademik/master/waktu",
                name: "Waktu",
                component: () => import("@/views/adm_akademik/Waktu.vue"),
              },
              {
                path: "/adm-akademik/master/shift",
                name: "Shift",
                component: () => import("@/views/adm_akademik/Shift.vue"),
              },
            ],
          },
          {
            path: "/adm-akademik/pengaturan",
            name: "Pengaturan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/adm-akademik/pengaturan/jadwalkrs",
            children: [
              {
                path: "/adm-akademik/pengaturan/jadwalkrs",
                name: "Jadwal Registrasi KRS",
                component: () =>
                  import("@/views/adm_akademik/JadwalRegisKRS.vue"),
              },
              {
                path: "/adm-akademik/pengaturan/kelas",
                name: "Kelas",
                component: () => import("@/views/adm_akademik/Kelas.vue"),
              },
              {
                path: "/adm-akademik/pengaturan/jadwalpmb",
                name: "Jadwal Registrasi PMB",
                component: () =>
                  import("@/views/adm_akademik/JadwalRegisPMB.vue"),
              },
              {
                path: "/adm-akademik/pengaturan/jadwalinputnilai",
                name: "Jadwal Input Nilai",
                component: () =>
                  import("@/views/adm_akademik/JadwalInputNilai.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: () => import("@/views/pages/Page404"),
      },
      {
        path: "500",
        name: "Page500",
        component: () => import("@/views/pages/Page500"),
      },
      {
        path: "admission",
        name: "Admission",
        component: () => import("@/views/pages/Admission"),
      },
      {
        path: "dela",
        name: "Dela",
        component: () => import("@/views/pages/Dela"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/pages/Login"),
      },
      {
        path: "parentslogin",
        name: "ParentsLogin",
        component: () => import("@/views/pages/ParentsLogin"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/pages/Register"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
