export default [
  {
    component: "CNavGroup",
    name: "Akun",
    to: "/akun",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/akun/beranda",
      },
      {
        component: "CNavGroup",
        name: "Profilku",
        to: "/akun/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Edit Profil",
            to: "/akun/profilku/editprofil",
          },
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Unduh Berkas",
        to: "/akun/unduhberkas",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Registrasi",
        to: "/registrasi/beranda",
      },
      {
        component: "CNavItem",
        name: "Tagihan Register",
        to: "/registrasi/reg/tagihanregis",
      },
      {
        component: "CNavItem",
        name: "Registrasi Mata Kuliah",
        to: "/registrasi/reg/registrasimatkul",
      },
      {
        component: "CNavItem",
        name: "History Proses Registrasi",
        to: "/registrasi/reg/historyregis",
      },
      {
        component: "CNavItem",
        name: "Arsip KSM",
        to: "/registrasi/reg/arsipksm",
      },
      {
        component: "CNavItem",
        name: "Status Registrasi Mahasiswa",
        to: "/registrasi/reg/statusregis",
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasi/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Mahasiswa",
            to: "/registrasi/jadwal/jadwalmhs",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasi/jadwal/jadwalujianmhs",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/registrasi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/registrasi/bantuan",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Nilai",
    to: "/nilai",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Nilai",
        to: "/nilai/beranda",
      },
      {
        component: "CNavGroup",
        name: "KHS",
        to: "/nilai/khs",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Ekivalensi",
            to: "/nilai/khs/lihatekivalensi",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai",
            to: "/nilai/khs/lihatnilai",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai Sementara",
            to: "/nilai/khs/lihatnilaisementara",
          },
        ],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
