export default [
  {
    component: "CNavGroup",
    name: "Kurikulum",
    to: "/kurikulum-kaprodi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/kurikulum-kaprodi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Kelola Kurikulum",
        to: "/kurikulum-kaprodi/kelolakurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Kurikulum",
            to: "/kurikulum-kaprodi/kelolakurikulum/lihatkurikulum",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/kurikulum-kaprodi/kelolakurikulum/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Impor Mata kuliah",
            to: "/kurikulum-kaprodi/kelolakurikulum/impormatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Persetujuan Kurikulum",
        to: "/kurikulum-kaprodi/persetujuankurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Riwayat Kurikulum",
            to: "/kurikulum-kaprodi/persetujuankurikulum/riwayatkurikulum",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Laporan Mata Kuliah",
        to: "/kurikulum-kaprodi/laporanmatkul",
      },
      {
        component: "CNavItem",
        name: "User Manual",
        to: "/kurikulum-kaprodi/usermanual",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi-kaprodi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/registrasi-kaprodi/beranda",
      },
      {
        component: "CNavItem",
        name: "Pilih Mahasiswa",
        to: "/registrasi-kaprodi/pilihmahasiswa",
      },
      {
        component: "CNavGroup",
        name: "Registrasi",
        to: "/registrasi-kaprodi/regis",
        items: [
          {
            component: "CNavItem",
            name: "Registrasi Mata Kuliah",
            to: "/registrasi-kaprodi/regis/regismatkul",
          },
          {
            component: "CNavItem",
            name: "Status Registrasi Mahasiswa",
            to: "/registrasi-kaprodi/regis/statusmahasiswa ",
          },
          {
            component: "CNavItem",
            name: "Arsip KSM",
            to: "/registrasi-kaprodi/regis/arsip",
          },
          {
            component: "CNavItem",
            name: "History Proses Registrasi",
            to: "/registrasi-kaprodi/regis/history",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Mata Kuliah",
        to: "/registrasi-kaprodi/matkul",
        items: [
          {
            component: "CNavItem",
            name: "Daftar Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/daftarmatkul",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Impor Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/impormatkul",
          },
          {
            component: "CNavItem",
            name: "Alokasi Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/alokasimatkul",
          },
          {
            component: "CNavItem",
            name: "Koordinator Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/koordinatormatkul",
          },
          {
            component: "CNavItem",
            name: "Status Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/statusmatkul",
          },
          {
            component: "CNavItem",
            name: "Mendaftarkan Mata Kuliah TA/PA",
            to: "/registrasi-kaprodi/matkul/tamatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/registrasi-kaprodi/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "Aturan Maks SKS",
            to: "/registrasi-kaprodi/pengaturan/aturansks",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Master",
        to: "/registrasi-kaprodi/master",
        items: [
          {
            component: "CNavItem",
            name: "Ruangan",
            to: "/registrasi-kaprodi/master/ruangan",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/jadwal-kaprodi",
        items: [
          {
            component: "CNavItem",
            name: "Tambah Jadwal",
            to: "/jadwal-kaprodi/tambahjadwal",
          },
          {
            component: "CNavItem",
            name: "Lihat Jadwal",
            to: "/jadwal-kaprodi/lihatjadwal",
          },
          {
            component: "CNavItem",
            name: "Ruang Kosong",
            to: "/jadwal-kaprodi/ruangkosong",
          },
          {
            component: "CNavItem",
            name: "Import Jadwal",
            to: "/jadwal-kaprodi/importjadwal",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi-kaprodi",
    items: [
      {
        component: "CNavGroup",
        name: "Laporan Perkuliahan",
        to: "/presensi-kaprodi/laporanperkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Rekap Kehadiran Dosen",
            to: "/presensi-kaprodi/laporanperkuliahan/rekapkehadirandosen",
          },
        ],
      },
    ],
  },
];
