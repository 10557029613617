export default [
  {
    component: "CNavGroup",
    name: "PMB Keuangan",
    to: "/pmb-keu",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-keu/beranda",
      },
      {
        component: "CNavItem",
        name: "Alur Sistem",
        to: "/pmb-keu/alursistem",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb-keu/pengajuanundurdiripeserta",
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb-keu/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Pengembalian Pembayaran",
            to: "/pmb-keu/pembayaran/pengembalian",
          },
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb-keu/pembayaran/input",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb-keu/pembayaran/konfirmasi",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb-keu/pembayaran/beasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb-keu/laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb-keu/laporan/laporanpembayarancetakba",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb-keu/laporan/laporanpesertapmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb-keu/laporan/laporandistribusijk",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb-keu/laporan/laporanpembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb-keu/laporan/laporandistribusiagama",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial Keuangan",
    to: "/finansial-keu",
    items: [
      {
        component: "CNavGroup",
        name: "Daftar Detail Laporan",
        to: "/finansial-keu/daftardetaillaporan",
        items: [
          {
            component: "CNavItem",
            name: "Finance Laporan Detail MABA",
            to: "/finansial-keu/daftardetaillaporan/financelaporandetailmaba",
          },
          {
            component: "CNavItem",
            name: "Finance Laporan Detail MALA",
            to: "/finansial-keu/daftardetaillaporan/financelaporandetailmala",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran Reguler",
        to: "/finansial-keu/pembayaranreguler",
        items: [
          {
            component: "CNavItem",
            name: "Data Pembayaran",
            to: "/finansial-keu/pembayaranreguler/datapembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/finansial-keu/pembayaranreguler/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Status Mahasiswa",
            to: "/finansial-keu/pembayaranreguler/statusmahasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Data Transaksi Bank",
        to: "/finansial-keu/datatransaksibank",
        items: [
          {
            component: "CNavItem",
            name: "Konfirmasi Transaksi Pembayaran",
            to: "/finansial-keu/datatransaksibank/konfirmasitransaksipembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Transaksi Rekon",
            to: "/finansial-keu/datatransaksibank/laporantransaksirekon",
          },
          {
            component: "CNavItem",
            name: "Transaksi Rekening Koran",
            to: "/finansial-keu/datatransaksibank/rekeningkoran",
          },
        ],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
