<template>
  <CFooter>
    <div style="text-align: center; margin-left: auto; margin-right: auto">
      <p style="margin-bottom: 0">
        KIIS Institut Bisnis dan Informatika Kesatuan Bogor © 2023
      </p>
      <a href="#" style="color: #59359a">IBI KESATUAN</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
