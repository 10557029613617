export default [
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi_baa",
    items: [
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi_baa/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi_baa/beritaacaraperkuliahan",
      },
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi_baa/kehadiranmahasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Mahasiswa",
            to: "/presensi_baa/kehadiranmahasiswa/lihatmahasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Perkuliahan",
        to: "/presensi_baa/Perkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Perkuliahan",
            to: "/presensi_baa/Perkuliahan/lihatperkuliahan",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Nilai",
        to: "/presensi_baa/nilai",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Input Nilai",
            to: "/presensi_baa/nilai/jadwalinputnilai",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan Jadwal",
        to: "/presensi_baa/bantuanjadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Dosen",
            to: "/presensi_baa/bantuanjadwal/jadwaldosen",
          },
        ],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
