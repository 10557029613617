<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-2">
      <CDropdownItem> <CIcon icon="cilUser" /> Ubah Profil </CDropdownItem>
      <CDropdownItem> <CIcon icon="cilLanguage" /> ENG </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cilSettings" /> Pengaturan Akun
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cilBell" /> Pengaturan Notifikasi
      </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from "@/assets/images/avatars/8.jpg";
export default {
  name: "AppHeaderDropdownAccnt",
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },
};
</script>
