export default [
  {
    component: "CNavGroup",
    name: "Nilai Dosen",
    to: "/nilaidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda_Nilai",
        to: "/nilaidosen/berandanilai",
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilaidosen/riwayatinput",
      },
      {
        component: "CNavItem",
        name: "List Mata Kuliah",
        to: "/nilaidosen/listmatkul",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi-dosen/bap",
    items: [
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi-dosen/kehadiranmhs",
        items: [
          {
            component: "CNavItem",
            name: "Kehadiran per Perkuliahan",
            to: "/presensi-dosen/kehadiranmhs/per-perkuliahan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi-dosen/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Jadwal Dosen",
        to: "/presensi-dosen/jadwaldosen",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi-dosen/bap",
      },

      {
        component: "CNavItem",
        name: "Evaluasi Pembelajaran",
        to: "/presensi-dosen/evaluasipembelajaran",
      },
      {
        component: "CNavItem",
        name: "Honor Kehadiran",
        to: "/presensi-dosen/honor",
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
