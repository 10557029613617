export default [
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },
        ],
      },

      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/pmb/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "Generete Kelas",
            to: "/pmb/pengaturan/generetekelas",
          },
          {
            component: "CNavItem",
            name: "Jadwal Seleksi Registrasi",
            to: "/pmb/pengaturan/jadwalseleksireg",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb/daftarPesertaOnSite",
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Beasiswa",
    to: "/beasiswa",
    items: [
      {
        component: "CNavItem",
        name: "Lihat Jenis Beasiswa",
        to: "/beasiswa/lihatjenisbeasiswa",
      },
      {
        component: "CNavGroup",
        name: "Beasiswa Mahasiswa",
        to: "/beasiswa/beasiswamahasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Jenis Beasiswa Mahasiswa",
            to: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
          },
          {
            component: "CNavItem",
            name: "Tambah Beasiswa",
            to: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
          },
          {
            component: "CNavItem",
            name: "Penerima Beasiswa",
            to: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
          },
          {
            component: "CNavItem",
            name: "Lihat Penerima Beasiswa Mahasiswa",
            to: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
          },
          {
            component: "CNavItem",
            name: "Import Penerima Beasiswa",
            to: "/beasiswa/beasiswamahasiswa/importpenerima",
          },
          {
            component: "CNavItem",
            name: "Aproval Penerima Beasiswa",
            to: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
          },
          {
            component: "CNavItem",
            name: "Aproval Status Penerima Beasiswa",
            to: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Beasiswa Mahasiswa Baru",
        to: "/beasiswa/beasiswamaba",
        items: [
          {
            component: "CNavItem",
            name: "Beasiswa Calon Mahasiswa",
            to: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
          },
          {
            component: "CNavItem",
            name: "Lihat Jenis Beasiswa Mahasiswa Baru",
            to: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
          },
          {
            component: "CNavItem",
            name: "Lihat Penerima Beasiswa",
            to: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan Beasiswa",
        to: "/beasiswa/laporanbeasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Beasiswa Per Semester",
            to: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
          },
          {
            component: "CNavItem",
            name: "Laporan Penerima Beasiswa",
            to: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
          },
          {
            component: "CNavItem",
            name: "Laporan Beasiswa Per Prodi",
            to: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
          },
          {
            component: "CNavItem",
            name: "Laporan Beasiswa Maba",
            to: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
          },
        ],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
