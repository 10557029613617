<template>
  <div class="d-flex justify-content-center align-items-center">
    <img src="../../assets/images/notfound.svg" class="w-25" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Page404",
};
</script>
