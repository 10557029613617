<template>
  <AppCard />
</template>
<script>
import AppCard from "@/components/AppCard.vue";

export default {
  name: "LayoutCard",
  components: {
    AppCard,
  },
};
</script>
