import { defineComponent, h, onMounted, ref, resolveComponent } from "vue";
import { RouterLink, useRoute } from "vue-router";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from "@coreui/vue";
import nav from "@/_nav.js";
import nav_mhs from "@/_nav-mhs";
import nav_adm_ads from "@/_nav-adm-ads";
import nav_adm from "@/_nav-adm";
import nav_keu from "@/_nav-keu";
import nav_dosen from "@/_nav-dosen";
import nav_new from "@/_nav-new";
import nav_kaprodi from "@/_nav-kaprodi";
import nav_camaba from "@/_nav-camaba";
import nav_baa from "@/_nav-baa";

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, "");

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
};

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute();
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              // h(resolveComponent("CIcon"), {
              //   customClassName: "nav-icon",
              //   name: item.icon,
              // }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          }
        );
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      // item.icon &&
                      //   h(resolveComponent("CIcon"), {
                      //     customClassName: "nav-icon",
                      //     name: item.icon,
                      //   }),
                      item.name,
                      // item.badge &&
                      //   h(
                      //     CBadge,
                      //     {
                      //       class: "ms-auto",
                      //       color: item.badge.color,
                      //     },
                      //     {
                      //       default: () => item.badge.text,
                      //     }
                      //   ),
                    ],
                  }
                ),
            }
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            }
          );
    };

    return () => {
      const role = localStorage.getItem("role");
      let renderNav;
      switch (role) {
        case "Mahasiswa":
          renderNav = nav_mhs;
          break;
        case "Admin Admission":
          renderNav = nav_adm_ads;
          break;
        case "Admission":
          renderNav = nav_adm;
          break;
        case "Keuangan":
          renderNav = nav_keu;
          break;
        case "Dosen":
          renderNav = nav_dosen;
          break;
        case "Kaprodi":
          renderNav = nav_kaprodi;
          break;
        case "Camaba":
          renderNav = nav_camaba;
          break;
        case "BAA":
          renderNav = nav_baa;
          break;
        case "Dev":
          renderNav = nav;
          break;
        default:
          renderNav = nav_new;
          break;
      }

      return h(
        CSidebarNav,
        {},
        {
          default: () => renderNav.map((item) => renderItem(item)),
        }
      );
    };
  },
});
export { AppSidebarNav };
