<template>
  <CHeader position="sticky" class="mb-4 bg-light">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" style="color: gray" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink class="text-dark" href="/">
            <img src="../assets/images/kisLogoUngu.png" style="height: 40px" />
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <div class="text-dark fw-bold d-flex align-items-center">
          Semester aktif : <CBadge color="secondary py-2 ms-2">2223 / 1</CBadge>
        </div>
        <CNavItem class="ms-2 d-flex align-items-center">
          <CDropdown color="transparant" alignment="end" class="dropdown">
            <CDropdownToggle
              ><CIcon icon="cil-list" size="lg" style="color: 472e7C" />
            </CDropdownToggle>
            <CDropdownMenu alignment="end">
              <CDropdownHeader style="font-weight: 500"
                >Grup Pengguna</CDropdownHeader
              >
              <CDropdownItem href="#" active>Mahasiswa</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CNavItem>
        <CNavItem class="ms-2 d-flex align-items-center">
          <CDropdown color="transparant" alignment="end">
            <CDropdownToggle
              ><CBadge color="secondary" style="margin-right: 0.5rem">0</CBadge
              ><CIcon
                icon="cil-envelope-open"
                size="lg"
                style="color: 472e7C"
              />
            </CDropdownToggle>
            <CDropdownMenu> </CDropdownMenu>
          </CDropdown>
        </CNavItem>

        <CNavItem class="ms-2 d-flex align-items-center">
          <CDropdown color="transparant" alignment="end">
            <CDropdownToggle
              ><CBadge color="secondary" style="margin-right: 0.5rem">0</CBadge
              ><CIcon icon="cil-bell" size="lg" style="color: 472e7C" />
            </CDropdownToggle>
            <CDropdownMenu alignment="end" class="menu">
              <CDropdownHeader style="font-weight: 500"
                >Notifikasi</CDropdownHeader
              >
              <CHeaderDivider />
              <CDropdownItem href="#" class="menu">...</CDropdownItem>
              <CDropdownItem href="#" class="menu">...</CDropdownItem>
              <CDropdownItem href="#" class="menu">...</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem href="#" class="menu"
                >Lihat Semua Notifikasi</CDropdownItem
              >
            </CDropdownMenu>
          </CDropdown>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from "./AppBreadcrumb";
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
import { logo } from "@/assets/brand/logo";
export default {
  name: "AppHeader",
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #59359a;
}
.menu:focus {
  background-color: #59359a;
}
</style>
