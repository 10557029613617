<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import "styles/style";
* {
  font-size: small;
}
</style>
