export default [
  //administrator akademik
  {
    component: "CNavGroup",
    name: "Administrator Akademik",
    to: "/adm-akademik",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/adm-akademik/beranda",
      },
      {
        component: "CNavGroup",
        name: "Master",
        to: "/adm-akademik/master",
        items: [
          {
            component: "CNavItem",
            name: "Fakultas",
            to: "/adm-akademik/master/fakultas",
          },
          {
            component: "CNavItem",
            name: "Program Studi",
            to: "/adm-akademik/master/prodi",
          },
          {
            component: "CNavItem",
            name: "Ruangan",
            to: "/adm-akademik/master/ruangan",
          },
          {
            component: "CNavItem",
            name: "Shift",
            to: "/adm-akademik/master/shift",
          },
          {
            component: "CNavItem",
            name: "Tahun Ajar",
            to: "/adm-akademik/master/thnajar",
          },
          {
            component: "CNavItem",
            name: "Tahun Kurikulum",
            to: "/adm-akademik/master/kurikulum",
          },
          {
            component: "CNavItem",
            name: "Waktu",
            to: "/adm-akademik/master/waktu",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/adm-akademik/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Input Nilai",
            to: "/adm-akademik/pengaturan/jadwalinputnilai",
          },
          {
            component: "CNavItem",
            name: "Jadwal Registrasi KRS",
            to: "/adm-akademik/pengaturan/jadwalkrs",
          },
          {
            component: "CNavItem",
            name: "Jadwal Registrasi PMB",
            to: "/adm-akademik/pengaturan/jadwalpmb",
          },
          {
            component: "CNavItem",
            name: "Kelas",
            to: "/adm-akademik/pengaturan/kelas",
          },
        ],
      },
    ],
  },

  //akun
  {
    component: "CNavGroup",
    name: "Akun",
    to: "/akun",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/akun/beranda",
      },
      {
        component: "CNavGroup",
        name: "Profilku",
        to: "/akun/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Edit Profil",
            to: "/akun/profilku/editprofil",
          },
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Unduh Berkas",
        to: "/akun/unduhberkas",
      },
    ],
  },

  //beasiswa
  {
    component: "CNavGroup",
    name: "Beasiswa",
    to: "/beasiswa",
    items: [
      {
        component: "CNavGroup",
        name: "Beasiswa Mahasiswa",
        to: "/beasiswa/beasiswamahasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Jenis Beasiswa Mahasiswa",
            to: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
          },
          {
            component: "CNavItem",
            name: "Penerima Beasiswa Mahasiswa",
            to: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Potongan Mahasiswa Baru",
        to: "/beasiswa/beasiswamaba",
        items: [
          {
            component: "CNavItem",
            name: "Jenis Potongan Maba",
            to: "/beasiswa/beasiswamaba/jenispotongan",
          },
          // {
          //   component: "CNavItem",
          //   name: "Lihat Jenis Potongan Maba",
          //   to: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
          // },
          {
            component: "CNavItem",
            name: "Lihat Penerima Potongan",
            to: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan Beasiswa",
        to: "/beasiswa/laporanbeasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Beasiswa Per Semester",
            to: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
          },
          {
            component: "CNavItem",
            name: "Laporan Penerima Beasiswa",
            to: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
          },
          {
            component: "CNavItem",
            name: "Laporan Beasiswa Per Prodi",
            to: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
          },
          {
            component: "CNavItem",
            name: "Laporan Potongan",
            to: "/beasiswa/laporanbeasiswa/laporanpotongan",
          },
        ],
      },
      // {
      //   component: "CNavItem",
      //   name: "Lihat Jenis Beasiswa",
      //   to: "/beasiswa/lihatjenisbeasiswa",
      // },
    ],
  },

  //kurikulum
  {
    component: "CNavGroup",
    name: "Kurikulum",
    to: "/kurikulum",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/kurikulum/beranda",
      },
      {
        component: "CNavGroup",
        name: "Kelola Kurikulum",
        to: "/kurikulum/kelolakurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Kurikulum",
            to: "/kurikulum/kelolakurikulum/lihatkurikulum",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/kurikulum/kelolakurikulum/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Impor Mata kuliah",
            to: "/kurikulum/kelolakurikulum/impormatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Persetujuan Kurikulum",
        to: "/kurikulum/persetujuankurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Riwayat Kurikulum",
            to: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
          },
          {
            component: "CNavItem",
            name: "Atur Approval",
            to: "/kurikulum/persetujuankurikulum/aturapproval",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Laporan Mata Kuliah",
        to: "/kurikulum/laporanmatkul",
      },
      {
        component: "CNavItem",
        name: "User Manual",
        to: "/kurikulum/usermanual",
      },
    ],
  },

  //nilai
  {
    component: "CNavGroup",
    name: "Nilai",
    to: "/nilai",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Nilai",
        to: "/nilai/beranda",
      },
      {
        component: "CNavGroup",
        name: "KHS",
        to: "/nilai/khs",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Ekivalensi",
            to: "/nilai/khs/lihatekivalensi",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai",
            to: "/nilai/khs/lihatnilai",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai Sementara",
            to: "/nilai/khs/lihatnilaisementara",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilai/riwayatinput",
      },
      {
        component: "CNavItem",
        name: "List Mata Kuliah",
        to: "/nilai/listmatkul",
      },
    ],
  },

  //nilai-dosen
  {
    component: "CNavGroup",
    name: "Nilai Dosen",
    to: "/nilaidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda_Nilai",
        to: "/nilaidosen/berandanilai",
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilaidosen/riwayatinput",
      },
      {
        component: "CNavItem",
        name: "List Mata Kuliah",
        to: "/nilaidosen/listmatkul",
      },
    ],
  },

  //pmb
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Form Pengunduran Diri",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },

          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pengembalian Dana DP",
            to: "/pmb/pembayaran/pengembalian",
          },
        ],
      },

      // {
      //   component: "CNavItem",
      //   name: "Registrasi",
      //   to: "/pmb/daftarPesertaOnSite",
      // },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  //pmb-camaba
  {
    component: "CNavGroup",
    name: "PMB Camaba",
    to: "/pmb-camaba",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-camaba/beranda",
      },
      {
        component: "CNavItem",
        name: "Upload Pernyataan Keuangan",
        to: "/pmb-camaba/uploadpernyataan",
      },
      {
        component: "CNavItem",
        name: "Status Pembayaran",
        to: "/pmb-camaba/statuspembayaran",
      },
      {
        component: "CNavItem",
        name: "Mengisi Data Peserta",
        to: "/pmb-camaba/mengisidatapeserta",
      },
      {
        component: "CNavItem",
        name: "Dokumen Peserta PMB",
        to: "/pmb-camaba/dokumenpeserta",
      },
      {
        component: "CNavGroup",
        name: "Cetak Dokumen",
        to: "/pmb-camaba/cetakdokumen",
        items: [
          {
            component: "CNavItem",
            name: "Berita Acara",
            to: "/pmb-camaba/cetakdokumen/beritaacara",
          },
          {
            component: "CNavItem",
            name: "Status Dokumen",
            to: "/pmb-camaba/cetakdokumen/statusdok",
          },
        ],
      },
    ],
  },

  //presensi
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi",
    items: [
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi/kehadiranmhs",
        items: [
          {
            component: "CNavItem",
            name: "Kehadiran per Perkuliahan",
            to: "/presensi/kehadiranmhs/per-perkuliahan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Jadwal Dosen",
        to: "/presensi/jadwaldosen",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi/bap",
      },

      {
        component: "CNavItem",
        name: "Rekap Kehadiran Dosen",
        to: "/presensi/laporanperkuliahan/rekapkehadirandosen",
      },

      {
        component: "CNavItem",
        name: "Evaluasi Pembelajaran",
        to: "/presensi/evaluasipembelajaran",
      },

      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/presensi/laporanperkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Rekap Kehadiran Dosen",
            to: "/presensi/laporanperkuliahan/rekapkehadirandosen",
          },
        ],
      },
    ],
  },

  //registrasi
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Registrasi",
        to: "/registrasi/beranda",
      },
      {
        component: "CNavItem",
        name: "Tagihan Register",
        to: "/registrasi/reg/tagihanregis",
      },
      {
        component: "CNavItem",
        name: "Registrasi Mata Kuliah",
        to: "/registrasi/reg/registrasimatkul",
      },
      {
        component: "CNavItem",
        name: "History Proses Registrasi",
        to: "/registrasi/reg/historyregis",
      },
      {
        component: "CNavItem",
        name: "Arsip KSM",
        to: "/registrasi/reg/arsipksm",
      },
      {
        component: "CNavItem",
        name: "Status Registrasi Mahasiswa",
        to: "/registrasi/reg/statusregis",
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasi/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Mahasiswa",
            to: "/registrasi/jadwal/jadwalmhs",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasi/jadwal/jadwalujianmhs",
          },
          {
            component: "CNavItem",
            name: "Import Jadwal",
            to: "/registrasi/jadwal/importjadwal",
          },
          {
            component: "CNavItem",
            name: "Jadwal Dosen",
            to: "/registrasi/jadwal/jadwaldosen",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/registrasi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/registrasi/bantuan",
          },
        ],
      },

      {
        component: "CNavItem",
        name: "Pilih Mahasiswa",
        to: "/registrasi/pilihmahasiswa",
      },
      {
        component: "CNavGroup",
        name: "Registrasi",
        to: "/registrasi/regis",
        items: [
          {
            component: "CNavItem",
            name: "Registrasi Mata Kuliah",
            to: "/registrasi/regis/regismatkul",
          },
          {
            component: "CNavItem",
            name: "Status Registrasi Mahasiswa",
            to: "/registrasi/regis/statusmahasiswa ",
          },
          {
            component: "CNavItem",
            name: "Arsip KSM",
            to: "/registrasi/regis/arsip",
          },
          {
            component: "CNavItem",
            name: "History Proses Registrasi",
            to: "/registrasi/regis/history",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Mata Kuliah",
        to: "/registrasi/matkul",
        items: [
          {
            component: "CNavItem",
            name: "Mata Kuliah Tawar",
            to: "/registrasi/matkul/daftarmatkul",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/registrasi/matkul/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Impor Mata Kuliah",
            to: "/registrasi/matkul/impormatkul",
          },
          {
            component: "CNavItem",
            name: "Alokasi Mata Kuliah",
            to: "/registrasi/matkul/alokasimatkul",
          },
          {
            component: "CNavItem",
            name: "Koordinator Mata Kuliah",
            to: "/registrasi/matkul/koordinatormatkul",
          },
          {
            component: "CNavItem",
            name: "Status Mata Kuliah",
            to: "/registrasi/matkul/statusmatkul",
          },
          {
            component: "CNavItem",
            name: "Mendaftarkan Mata Kuliah TA/PA",
            to: "/registrasi/matkul/tamatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/registrasi/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "Aturan Maks SKS",
            to: "/registrasi/pengaturan/aturansks",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Master",
        to: "/registrasi/master",
        items: [
          {
            component: "CNavItem",
            name: "Ruangan",
            to: "/registrasi/master/ruangan",
          },
        ],
      },
      // {
      //   component: "CNavGroup",
      //   name: "Jadwal",
      //   to: "/jadwal-kaprodi",
      //   items: [
      //     {
      //       component: "CNavItem",
      //       name: "Tambah Jadwal",
      //       to: "/jadwal-kaprodi/tambahjadwal",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Lihat Jadwal",
      //       to: "/jadwal-kaprodi/lihatjadwal",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Ruang Kosong",
      //       to: "/jadwal-kaprodi/ruangkosong",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Import Jadwal",
      //       to: "/jadwal-kaprodi/importjadwal",
      //     },
      //   ],
      // },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
