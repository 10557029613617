export default [
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb-camaba",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-camaba/beranda",
      },
      {
        component: "CNavItem",
        name: "Upload Pernyataan Keuangan",
        to: "/pmb-camaba/uploadpernyataan",
      },
      {
        component: "CNavItem",
        name: "Status Pembayaran",
        to: "/pmb-camaba/statuspembayaran",
      },
      {
        component: "CNavItem",
        name: "Mengisi Data Peserta",
        to: "/pmb-camaba/mengisidatapeserta",
      },
      {
        component: "CNavItem",
        name: "Dokumen Peserta PMB",
        to: "/pmb-camaba/dokumenpeserta",
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
