export default [
  {
    component: "CNavGroup",
    name: "PMB Admission",
    to: "/pmb-adm",
    items: [
      {
        component: "CNavItem",
        name: "Import Participant PMB",
        to: "/pmb-adm/importpmb",
      },
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-adm/beranda",
      },
      {
        component: "CNavItem",
        name: "Alur Sistem",
        to: "/pmb-adm/alursistem",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb-adm/laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb-adm/laporan/kontakpeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb-adm/laporan/pembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb-adm/laporan/CetakBa",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb-adm/laporan/regispertanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb-adm/laporan/agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb-adm/laporan/gender",
          },
          {
            component: "CNavItem",
            name: "Laporan Ukuran Baju",
            to: "/pmb-adm/laporan/ukuranbaju",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb-adm/registrasi",
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
